import Const from './CONSTANS'

export async function serviceGenerateIdenty(mode, credentials, logger) {
  const { urlIdenty } = mode === 'dev' ? Const.dev : Const.prod
  try {
    const identy = await fetch(`${urlIdenty}`, {
      method: 'POST',
      headers: {
        apikey: credentials.key
      },
      body: JSON.stringify({
        uuid: credentials.id
      })
    })
    if (identy.ok) {
      const identyJson = await identy.json()
      const auth = await generateToken(
        mode,
        identyJson.payload,
        credentials.key,
        logger
      )
      logger.log('[OK] Credentials loaded')
      return Promise.resolve(auth.token)
    } else {
      if (identy.status !== 200) {
        if (identy.status === 401) {
          throw Error(
            'Las llaves utilizadas no son validas, verifica si son las correctas.'
          )
        } else if (identy.status === 404) {
          throw Error(
            'Utiliza la última versión del SDK, si persiste el problema contacta a soporte@dicio.ai'
          )
        } else if (identy.status === 500) {
          throw Error(
            'Problema general con el servicio, contacta a soporte@dicio.ai'
          )
        } else {
          throw Error('No se puede establecer la autenticidad de la llave')
        }
      }
    }
  } catch (error) {
    logger.error(
      `[ERROR] No se puede establecer comunicación verifica tu conexión ${error}`
    )
    return Promise.resolve(null)
  }
}

async function generateToken(mode, identy, apikey, logger) {
  const { urlAuth } = mode === 'dev' ? Const.dev : Const.prod
  try {
    const auth = await fetch(`${urlAuth}`, {
      method: 'POST',
      headers: {
        apikey: apikey
      },
      body: JSON.stringify({
        user: {
          uuid: identy.id,
          key: identy.key
        }
      })
    })
    if (auth.ok) {
      const authJson = await auth.json()
      logger.log('[OK] Token temp authorirized ok')
      return Promise.resolve(authJson.payload)
    } else {
      if (auth.status !== 200) {
        if (auth.status === 401) {
          throw Error(
            'Las llaves utilizadas no son validas, verifica si son las correctas.'
          )
        } else if (auth.status === 404) {
          throw Error(
            'Utiliza la última versión del SDK, si persiste el problema contacta a soporte@dicio.ai'
          )
        } else if (auth.status === 500) {
          throw Error(
            'Problema general con el servicio, contacta a soporte@dicio.ai'
          )
        } else {
          throw Error('No se puede establecer la autenticidad de la llave')
        }
      }
    }
  } catch (error) {
    logger.error(
      `[ERROR] No se puede establecer comunicación verifica tu conexión ${error}`
    )
    return Promise.resolve(null)
  }
}
