export default function Logger(debug) {
  const libName = 'DICIO PIVOT'
  return debug
    ? {
        info: (m, d) => {
          console.info(
            `%c${libName} -> ${m}`,
            'color: #3498db; font-weight: bold; font-size: 0.7rem;',
            d || ''
          )
        },
        warn: (m, d) => {
          console.warn(
            `%c${libName} -> ${m}`,
            'color: #e67e22; font-weight: bold; font-size: 0.7rem;',
            d || ''
          )
        },
        log: (m, d) => {
          console.log(
            `%c${libName} -> ${m}`,
            'color: #1abc9c; font-weight: bold; font-size: 0.7rem;',
            d || ''
          )
        },
        error: (m, d) => {
          console.error(
            `%c${libName} -> ${m}`,
            'color: #e74c3c; font-weight: bold; font-size: 0.7rem;',
            d || ''
          )
        },
        banner: () => {
          console.log(
            '%cDICIO%cPIVOT',
            'color: #3498db; font-weight: bold; font-size: 1rem;',
            'color: #e67e22; font-weight: bold; font-size: 1rem;'
          )
        }
      }
    : {
        info: (m, d) => {
          return ''
        },
        warn: (m, d) => {
          return ''
        },
        log: (m, d) => {
          return ''
        },
        error: (m, d) => {
          return ''
        },
        banner: () => {
          return ''
        }
      }
}
