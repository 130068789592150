const urlBaseDev = 'https://api.dev.devdicio.net';
const urlBaseProd = 'https://srvs.proddicio.net:8444';

export default {
  dev: {
    urlIdenty: `${urlBaseDev}/v1/sec_qa_identy`,
    urlAuth: `${urlBaseDev}/v1/sec_qa_auth`,
    urlPivot: 'https://pivot.dev.devdicio.net'
  },
  prod: {
    urlIdenty: `${urlBaseProd}/v1/sec_prod_identy`,
    urlAuth: `${urlBaseProd}/v1/sec_prod_auth`,
    urlPivot: `https://pivot.proddicio.net`
  }
}
