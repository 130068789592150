const styleBase = {
  width: '100%',
  height: '34px',
  border: 'none',
  boxSizing: 'border-box',
  display: 'inline-block',
  fontWeight: '400',
  textAlign: 'center',
  verticalAlign: 'middle',
  userSelect: 'none',
  padding: '0',
  lineHeight: '1',
  position: 'relative',
  marginBottom: '0.3125rem',
  fontSize: '12px',
  textDecoration: 'none',
  textTransform: 'none',
  letterSpacing: '0.02rem',
  cursor: 'pointer',
  outline: '0',
  background:
    'linear-gradient(180deg, rgba(114, 116, 130, 1) 0%, rgba(65, 67, 84, 1) 43%, rgba(21, 25, 33, 1) 100%)',
  marginTop: '4px',
  boxShadow: '10 10 20px #000',
  borderRadius: '4px',
  animationDelay: '3s',
  color: '#fff',
  borderColor: '#009688'
}

export default {
  btn_base: styleBase,
  btn_original: {
    btn: {},
    span: {
      width: '36px',
      height: '34px',
      display: 'inline-block',
      margin: '-4px 0 0 0',
      padding: '0'
    },
    b: {
      display: 'inline-block',
      position: 'relative',
      top: '-16px'
    }
  },
  btn_only_text: {},
  btn_only_logo: {
    btn: {},
    span: {
      width: '88px',
      height: '34px',
      display: 'inline-block',
      margin: '-4px 0 0 0',
      padding: '0'
    }
  }
}
