import React, { Fragment, useEffect, useState } from 'react';
import { serviceGenerateIdenty } from './modules/connect';
import PropTypes from 'prop-types';
import Style from './modules/styles';
import { ReactComponent as SvgIcon } from './assets/splash_ico.svg';
import { ReactComponent as SvgLogo } from './assets/splash_logo.svg';
import Logger from './modules/logger';
import Const from './modules/CONSTANS';

const Dicio = ({
  auth,
  text = 'Iniciar proceso',
  variant = 'original',
  debug = true,
  mode = 'dev',
  status = (st) => {
    Logger(debug).log(
      '[INFO] Para escuchar eventos del proceso define una funcion en la propiedad "status={(data)=>{console.log(data)}}"',
      st
    )
  },
  errors = (er) => {
    Logger(debug).log(
      '[INFO] Para escuchar eventos de errores define una funcion en la propiedad "errors={(error)=>{console.log(error)}}"',
      er
    )
  },
  finish = (fsh) => {
    Logger(debug).log(
      '[INFO] Para escuchar el termino del proceso define una funcion en la propiedad "finish={(data)=>{console.log(data)}}"',
      fsh
    )
  }
}) => {
  const { urlPivot } = mode === 'dev' ? Const.dev : Const.prod
  const logger = Logger(debug)

  const [styleBtn, setStyleBtn] = useState(Style.btn_base)
  const [active, setActive] = useState(true)

  logger.banner()
  logger.log('[OK] Process environment -> ' + mode.toUpperCase())

  const messageEvents = (evento) => {
    if (evento.origin !== new URL(urlPivot).origin) {
      return
    }
    var infoprocess = JSON.parse(evento.data)
    if (infoprocess.step === 'completed') {
      logger.log('[OK] Finish process', infoprocess)
      finish(infoprocess)
      window.processdicio.close()
    } else {
      if (infoprocess.result === 'OK') {
        logger.info('[OK] Status process', infoprocess)
        status(infoprocess)
      } else {
        logger.error('[ERROR] Error process', infoprocess)
        errors(infoprocess)
      }
    }
  }

  useEffect(() => {
    setVariant(variant)
  }, [])

  useEffect(() => {
    window.addEventListener('message', messageEvents)
    return () => {
      window.removeEventListener('message', messageEvents)
    }
  }, [messageEvents])

  const setVariant = () => {
    switch (variant) {
      case 'only_text':
        setStyleBtn(Object.assign(Style.btn_base, Style.btn_only_text.btn))
        break
      case 'only_logo':
        setStyleBtn(Object.assign(Style.btn_base, Style.btn_only_logo.btn))
        break
      default:
        /* original */
        setStyleBtn(Object.assign(Style.btn_base, Style.btn_original.btn))
        break
    }
    logger.log(`[OK] Button ${variant.toUpperCase()} loaded`)
  }

  const initFlow = async (e) => {
    logger.log('[OK] Event click for init process')
    const token = await serviceGenerateIdenty(mode, auth, logger)
    if (token === undefined || token === null) {
      logger.error('[ERROR] No es posible iniciar el flujo')
      errors({
        step: 'credentials_check',
        type: 'LOCAL',
        error_text: 'No es posible iniciar el flujo'
      })
    } else {
      setActive(false)
      launchDicio(token)
    }
  }

  const launchDicio = (token) => {
    const urlHostProcess = `${urlPivot}/${auth.key}?referer=${window.location}&jwt=${token}`
    window.processdicio = window.open(
      `${urlHostProcess}`,
      'dicio',
      'status=yes, toolbar=no, menubar=no, location=no, addressbar=no'
    )
    try {
      window.processdicio.focus()
    } catch (e) {
      logger.error(
        `[ERROR] Se necesitan activar las ventanas emergentes para ese sitio en la configuración de su navegador.`
      )
      window.alert(
        `[ERROR] Se necesitan activar las ventanas emergentes para ese sitio en la configuración de su navegador.`
      )
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        width: 'auto',
        height: 'auto'
      }}
    >
      <button
        type='button'
        id='dco_btn_main'
        style={styleBtn}
        onClick={initFlow}
      >
        {variant === 'only_text' ? (
          <b>{text}</b>
        ) : variant === 'only_logo' ? (
          <span style={Style.btn_only_logo.span}>
            <SvgLogo />
          </span>
        ) : (
              <Fragment>
                <span style={Style.btn_original.span}>
                  <SvgIcon />
                </span>
                <b style={Style.btn_original.b}>{text}</b>
              </Fragment>
            )}
      </button>
    </div>
  )
}

Dicio.propTypes = {
  auth: PropTypes.object.isRequired,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['only_text', 'original', 'only_logo']),
  debug: PropTypes.bool,
  mode: PropTypes.oneOf(['dev', 'prod']),
  status: PropTypes.func,
  errors: PropTypes.func,
  finish: PropTypes.func.isRequired
}

export { Dicio }
